/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import nexo from '../../nexoClient';
import tokens from '@nimbus-ds/tokens/dist/js/tokens';
import { Page, Layout, FormField } from '@nimbus-ds/patterns';
import {
  Card,
  Box,
  Button,
  Select,
  Label,
  Checkbox,
  useToast,
  Tag,
  Text,
  Title,
  Modal,
  IconButton,
  Icon,
  Link,
  Tooltip,
  Table,
  Input,
} from '@nimbus-ds/components';
import { navigateHeader } from '@tiendanube/nexo/helpers';

import axiosInstance from '../../axios';
import { useWindowWidth } from '../../hooks';
import { BoxPackedIcon, CogIcon, QuestionCircleIcon } from '@nimbus-ds/icons';

const ConfigPage: React.FC = () => {
  const { push } = useHistory();
  const windowWidth = useWindowWidth();
  const breakpointMd = tokens.breakpoint.md.value.replace('px', '');
  const { addToast } = useToast();
  const [isModalOpen, setModalOpen] = useState(false);
  const [isModalOpen2, setModalOpen2] = useState(false);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [warehouses, setWareHouses] = useState<Warehouse[]>([]);
  const [inventories, setInventories] = useState<Inventory[]>([]);
  const [documents, setDocuments] = useState<Document[]>([]);
  const [costCenters, setCostCenters] = useState<CostCenter[]>([]);
  const [paymentsTn, setPaymentsTn] = useState([]);
  const [paymentsSiigo, setPaymentsSiigo] = useState<PaymentSiigo[]>([]);
  const [taxes, setTaxes] = useState<Tax[]>([]);
  const [warehouseSKU, setWarehouseSKU] = useState<Warehouse>();
  let warehouse_id_stock: number | null = null;
  let warehouse_id_sync_products: number | null = null;
  const [w, setW] = useState();
  const [w2, setW2] = useState();

  const [siigoShippingItemCode, setSiigoShippingItemCode] = useState<
    string | null
  >();
  const [siigoShippingItemName, setSiigoShippingItemName] = useState<
    string | null
  >();

  const [warehouseSyncProducts, setWarehouseSyncProducts] =
    useState<Warehouse>();
  const [inventorySyncProducts, setInventorySyncProducts] =
    useState<Inventory>();

  /* config orders */
  const [configPayments, setConfigPayments] = useState<ConfigPayments[]>([]);
  const [dian, setDian] = useState(false);
  const [email, setEmail] = useState(false);
  const [generateOrderOn, setGenerateOrderOn] = useState<string | null>();
  const [orderIsActive, setOrderIsActive] = useState(false);
  const [flagSyncSiigoToTn, setFlagSyncSiigoToTn] = useState(false);
  const [flagSyncTnToSiigo, setFlagSyncTnToSiigo] = useState(false);
  const [flagDian, setFlagDian] = useState(false);
  const [flagEmail, setFlagEmail] = useState(false);
  const [buttonDisabledSyncStockUnitary, setButtonDisabledSyncStockUnitary] =
    useState(false);
  const [configTax, setConfigTax] = useState<Tax>();
  const [configDocument, setConfigDocument] = useState<Document>();
  const [configCostCenter, setConfigCostCenter] = useState<CostCenter>();
  const [configPayment, setConfigPayment] = useState<ConfigPayments>();

  /* config products sync Siigo to TN */
  const [showMessageSync, setShowMessageSync] = useState(false);
  const [inventory, setInventory] = useState<Inventory>();
  /* config products sync TN to Siigo */
  const [showMessageSync2, setShowMessageSync2] = useState(false);
  const [inventory2, setInventory2] = useState<Inventory>();
  const [taxSync, setTaxForSync] = useState<Tax>();
  /* config for sync stock automation */
  const [inventorySyncStock, setInventorySyncStock] = useState<Inventory>();
  const [warehouseSyncStock, setWarehouseSyncStock] = useState<Warehouse>();
  //const [inventory3, setInventory3] = useState<Inventory>();
  /* config for unitary sync stock */
  const [inventorySyncStockUnitary, setInventorySyncStockUnitary] =
    useState<Inventory>();

  const [productFilter, setProductFilter] = useState<string>();
  const [productNameFilter, setProductNameFilter] = useState<string>();
  const [currentSeller, setCurrentSeller] = useState<Seller>();
  const [sellers, setSellers] = useState<Seller[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  const [marketPlaceCategories, setMarketPlaceCategories] = useState<
    Category[]
  >([]);

  useEffect(() => {
    navigateHeader(nexo, { goTo: '/', text: 'Volver al inicio' });
  }, []);

  const goToLogs = () => push('/logs');
  const goToSetup = () => push('/setup');

  //const params = useParams();
  //console.log(params);
  const location = useLocation();
  //console.log(location);
  //const history = useHistory();
  //console.log(history);

  /*
  useEffect(() => {
    console.log(location);
    console.log(location.pathname); // result: '/secondpage'
    console.log(location.search); // result: '?query=abc'
  }, [location]);
  */

  interface Seller {
    store_id: number;
    nombre_tienda: string;
    url: string;
  }

  interface Product {
    store_id: number;
    tn_id: number;
    name: string;
    handle: string;
    active: boolean;
    vinculated_category_id: number;
    vinculated_category_name: string;
    sales_count: number | null;
    total_sales_count: number | null;
    sync: number;
  }

  interface Category {
    id: number;
    name: string;
  }

  interface Warehouse {
    warehouse_id: string;
    name: string;
  }

  interface Inventory {
    inventory_id: string;
    name: string;
  }

  interface Tax {
    tax_id: string;
    name: string;
  }

  interface PaymentTn {
    payment_tn_id: string;
    name: string;
  }

  interface PaymentSiigo {
    payment_siigo_id: string;
    name: string;
  }

  interface Document {
    document_siigo_id: string;
    document_siigo_code: string;
    name: string;
  }

  interface CostCenter {
    cost_center_id: string;
    code: string;
    name: string;
  }

  interface ConfigPayments {
    payment_tn_id: string;
    payment_siigo_id: string;
    name: string;
  }

  useEffect(() => {
    setProductFilter('Pendientes');
    getSellersList();
    getMarketPlaceCategories();
    console.log(location.search);
    if (location.search && location.search.includes('?')) {
      console.log('includes!');
      const store = location.search.replace('?', '');
      console.log('store : ', store);
      if (!isNaN(parseInt(store))) {
        handleInitSellerProducts(parseInt(store));
      } else {
        console.log('is nan');
      }
    }
  }, []);

  const handleInitSellerProducts = async (store_id: number) => {
    setTimeout(() => {
      console.log('handle init seller products');
      for (const seller of sellers) {
        console.log(seller.store_id, ' ', store_id);
        if (seller.store_id == store_id) {
          console.log('seller found');
          const currentSeller: Seller = {
            store_id: seller.store_id,
            nombre_tienda: seller.nombre_tienda,
            url: seller.url,
          };
          setProductNameFilter('');
          setCurrentSeller(currentSeller);
          getSellerProducts(seller.store_id);
          break;
        }
      }
    }, 2000);
  };

  const syncClients = async () => {
    console.log('sync clientes call');
    await axiosInstance
      .post('/sync_clients', {
        bodega: '',
      })
      .then((response) => {
        console.log(response);
        addToast({
          id: 'sync-clients-toast-success',
          type: 'success',
          text: 'Sincronizando Clientes...',
          duration: 4000,
        });
      })
      .catch((e) => {
        addToast({
          id: 'sync-clientes-toast-fail',
          type: 'danger',
          text:
            e.response.data?.message ?? 'Error intentando sincronizar clientes',
          duration: 4000,
        });
        console.log(e);
      });

    return;
  };

  const onModalSyncClose = async () => {
    console.log('on modal sync close call');
    setModalOpen(false);
    console.log('is modal open? ', isModalOpen);
  };

  const onModalSyncClose2 = async () => {
    console.log('on modal sync close call');
    setModalOpen2(false);
    console.log('is modal open? ', isModalOpen2);
  };

  const onButtonDisabled = async () => {
    console.log('on button disabled');
    setButtonDisabled(true);
  };

  const onShowMessageSync = async () => {
    setShowMessageSync(true);
  };

  const onShowMessageSync2 = async () => {
    setShowMessageSync2(true);
  };

  const handleChangeBodegaStock = (e: any) => {
    console.log(e.target.value);
    warehouse_id_stock = e.target.value;
  };

  const handleChangeDianCheckbox = (e: any) => {
    console.log(e.target.value);
    console.log(e.target.checked);
    setDian(e.target.checked);
  };

  const handleChangeEmailCheckbox = (e: any) => {
    console.log(e.target.value);
    console.log(e.target.checked);
    setEmail(e.target.checked);
  };

  const handleChangeBodegaSyncProducts = (e: any) => {
    warehouse_id_sync_products = e.target.value ?? null;
    console.log(warehouse_id_sync_products);
    setW(e.target.value ?? null);
  };

  const handleChangeBodegaSyncProducts2 = (e: any) => {
    console.log('handle change bodega sync 2');
    console.log(e.target.value);
    setW2(e.target.value ?? null);
  };

  const handleChangeInventorySyncStockUnitary = (e: any) => {
    console.log('handle change inventory sync stock unitary: ', e.target.value);
    for (const inventory of inventories) {
      if (inventory.inventory_id == e.target.value) {
        console.log('inventory found');
        const inventory_opts: Inventory = {
          inventory_id: e.target.value,
          name: inventory.name,
        };
        setInventorySyncStockUnitary(inventory_opts);
      }
    }
  };

  const saveSyncStocksConfig = async () => {
    console.log('save sync stock config call');

    console.log('config sync stock to send:');
    console.log(inventorySyncStock, warehouseSyncStock);

    if (!inventorySyncStock && !warehouseSyncStock) {
      addToast({
        id: 'inventory-sync-stock-toast-warning',
        type: 'danger',
        text: 'Ingrese un inventario o una bodega',
        duration: 4000,
      });
      return;
    }

    await axiosInstance
      .post('/config_sync_stock', {
        inventario: inventorySyncStock?.inventory_id ?? null,
        warehouse: warehouseSyncStock?.warehouse_id ?? null,
      })
      .then((response) => {
        console.log(response);
        addToast({
          id: 'inventory-sync-stock-toast-success',
          type: 'success',
          text: 'Configuración actualizada ',
          duration: 4000,
        });
      })
      .catch((e) => {
        addToast({
          id: 'inventory-sync-stock-toast-error',
          type: 'danger',
          text:
            e.response.data?.message ??
            'No se pudo actualizar la configuración',
          duration: 4000,
        });
        console.log(e);
      });

    return;
  };

  const handleChangeProductsView = async (e: any) => {
    console.log(e.target.value);
    setProductFilter(e.target.value);
  };

  const handleFilterProductName = async (e: any) => {
    console.log(e.target.value);
    setProductNameFilter(e.target.value);
  };

  const getMarketPlaceCategories = async () => {
    await axiosInstance
      .get('/marketplace_categories')
      .then((response) => {
        setMarketPlaceCategories(response?.data?.categories);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const headers = [
    'Id',
    'Nombre',
    'Link producto',
    'Activo',
    'Ventas',
    'Ventas unitarias',
    'Categoria',
    'Acción',
  ];

  const getSellersList = async () => {
    console.log('get sellers list');

    await axiosInstance
      .get('/approved_sellers_list')
      .then((response) => {
        setSellers(response?.data?.sellers);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleChangeSellerSelect = async (e: any) => {
    console.log('handle change seller list');
    console.log(e.target.value);
    console.log(e.target.label);
    for await (const seller of sellers) {
      if (seller.store_id == e.target.value) {
        console.log('seller found');
        const currentSeller: Seller = {
          store_id: seller.store_id,
          nombre_tienda: seller.nombre_tienda,
          url: seller.url,
        };
        setProductNameFilter('');
        setCurrentSeller(currentSeller);
        getSellerProducts(seller.store_id);
        break;
      }
    }
  };

  const getSellerProducts = async (store_id: number) => {
    console.log('get seller products for ', store_id);
    await axiosInstance
      .get('/seller_products', {
        params: {
          store_id: store_id,
        },
      })
      .then((response) => {
        console.log(response.data);
        setProducts(response.data.products);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleChangeVinculateProductCategory = async (e: any) => {
    console.log(e.target.id);
    console.log(e.target.value);

    const target_product = products.find((p) => p.tn_id == e.target.id);

    if (target_product) {
      const target_category = marketPlaceCategories.find(
        (c) => c.name == e.target.value,
      );
      if (target_category) {
        console.log('target category id', target_category.id);
        target_product.vinculated_category_id = target_category.id;
        target_product.vinculated_category_name = target_category.name;
        addToast({
          id: 'category-change-success-toast',
          type: 'success',
          text: 'categoria asignada',
          duration: 4000,
        });
      } else {
        console.log('ERROR TARGET CATEGORY NOT FOUND');
        addToast({
          id: 'category-change-error-toast-1',
          type: 'danger',
          text: 'categoria no encontrada',
          duration: 4000,
        });
      }
    } else {
      console.log('ERROR TARGET PRODUCT NOT FOUND');
      addToast({
        id: 'category-change-error-toast-2',
        type: 'danger',
        text: 'producto no encontrado',
        duration: 4000,
      });
    }

    //const target_product_check = products.find((p) => (p.tn_id = e.target.id));
    //console.log('target product updated data? ', target_product_check);
  };

  const approveProduct = async (product_id: number) => {
    console.log('approve product with id ', product_id);

    const target_product = products.find((p) => p.tn_id == product_id);

    if (target_product) {
      if (
        target_product.vinculated_category_id &&
        target_product.vinculated_category_name
      ) {
        await axiosInstance
          .post('/approve_product', {
            store_id: target_product.store_id,
            product_id: target_product.tn_id,
            category_id: target_product.vinculated_category_id,
          })
          .then((response) => {
            console.log(response);
            if (response.data.status == true) {
              addToast({
                id: 'approve-product-toast-success',
                type: 'success',
                text: 'Vinculado producto... podrás ver el resultado en registros',
                duration: 4000,
              });
              //remove product for list?
              const f_products = products.filter(
                (p) => p.tn_id != target_product.tn_id,
              );
              console.log(f_products);
              setProducts(f_products);
            }
          })
          .catch((e) => {
            addToast({
              id: 'approve-product-toast-error',
              type: 'danger',
              text:
                e.response.data?.message ?? 'No se pudo aprobar el producto',
              duration: 4000,
            });
            console.log(e);
          });
      } else {
        console.log('ERROR TARGET PRODUCT CATEGORY DATA NOT FOUND');
        addToast({
          id: 'approved-product-error-toast-1',
          type: 'danger',
          text: 'datos de categoria de producto no encontrados',
          duration: 4000,
        });
      }
    } else {
      console.log('ERROR TARGET PRODUCT NOT FOUND');
      addToast({
        id: 'approved-product-error-toast-2',
        type: 'danger',
        text: 'producto no encontrado',
        duration: 4000,
      });
    }
  };

  const actions =
    windowWidth !== null && windowWidth <= breakpointMd ? (
      <IconButton
        backgroundColor="transparent"
        borderColor="transparent"
        source={<QuestionCircleIcon />}
        size="2rem"
      />
    ) : (
      <Box display="flex" gap="4">
        <Button appearance="primary">
          <Link as="a" href="" target="_blank" rel="noopener noreferrer">
            REFRESCAR
          </Link>
        </Button>
      </Box>
    );

  return (
    <Page maxWidth="1300px">
      <Page.Header title="Productos" buttonStack={actions} />
      <Page.Body>
        <Card>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap="4"
            marginBottom="6"
          >
            <Text>Seleccionar Seller</Text>
            <Select
              appearance="neutral"
              id="seller-list-select"
              name="Name"
              onChange={(event) => {
                handleChangeSellerSelect(event);
              }}
            >
              <Select.Option
                disabled
                label="Seleccionar Seller"
                selected={currentSeller == null}
                value="Seleccionar Seller"
              />
              {sellers ? (
                sellers.map((seller: Seller) => (
                  <Select.Option
                    key={seller.store_id}
                    value={String(seller.store_id)}
                    label={seller.nombre_tienda}
                  ></Select.Option>
                ))
              ) : (
                <Select.Skeleton />
              )}
            </Select>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap="4"
            marginBottom="6"
          >
            <Text>Mostrar productos</Text>
            <Select
              appearance="neutral"
              id="seller-list-select"
              name="Name"
              onChange={(event) => {
                handleChangeProductsView(event);
              }}
            >
              <Select.Option label="Pendientes" value="Pendientes" />
              <Select.Option label="Aprobados" value="Aprobados" />
              <Select.Option label="Todos" value="Todos" />
            </Select>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap="4"
            maxWidth="300px"
          >
            <Text>Filtro nombre</Text>
            <Input
              appearance="neutral"
              id="seller-filter-name"
              name="FilterName"
              onChange={(event) => {
                handleFilterProductName(event);
              }}
            />
          </Box>
        </Card>
        <Card>
          <Title fontSize="4">Listado de productos</Title>
          <Table>
            <Table.Head>
              {headers.map((header) => (
                <Table.Cell key={header}>{header}</Table.Cell>
              ))}
            </Table.Head>
            <Table.Body>
              {products.map((product) => (
                <Table.Row
                  key={product.tn_id}
                  hidden={
                    (productFilter == 'Pendientes' && product.sync == 1) ||
                    (productFilter == 'Aprobados' && product.sync == 0)
                  }
                >
                  <Table.Cell>{product.tn_id}</Table.Cell>
                  <Table.Cell>{product.name}</Table.Cell>
                  <Table.Cell>
                    {currentSeller ? (
                      <a
                        href={
                          currentSeller.url + '/productos/' + product.handle
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        {' '}
                        {currentSeller.url +
                          '/productos/' +
                          product.handle}{' '}
                      </a>
                    ) : (
                      ''
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {product.active == true ? 'activo' : 'inactivo'}
                  </Table.Cell>
                  <Table.Cell>{product.sales_count ?? 0}</Table.Cell>
                  <Table.Cell>{product.total_sales_count ?? 0}</Table.Cell>
                  <Table.Cell>
                    {product.sync == 0 ? (
                      <Select
                        appearance="neutral"
                        id={String(product.tn_id)}
                        name="Name"
                        onChange={(event) => {
                          handleChangeVinculateProductCategory(event);
                        }}
                      >
                        <Select.Option
                          disabled={product.vinculated_category_id != null}
                          label="Seleccionar categoria"
                          selected={product.vinculated_category_id == null}
                          value="Seleccionar categoria"
                        />
                        {marketPlaceCategories ? (
                          marketPlaceCategories.map((category: Category) => (
                            <Select.Option
                              key={product.store_id + '%' + category.id}
                              value={category.name}
                              label={category.name}
                            ></Select.Option>
                          ))
                        ) : (
                          <Select.Skeleton />
                        )}
                      </Select>
                    ) : (
                      <Tag appearance="success">
                        <BoxPackedIcon size={12} />
                        <Text color="success-textLow">
                          {product.vinculated_category_name}
                        </Text>
                      </Tag>
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {product.sync == 0 ? (
                      <Button
                        onClick={() => approveProduct(product.tn_id)}
                        appearance="primary"
                      >
                        Aprobar
                      </Button>
                    ) : (
                      <Tag appearance="success">
                        <BoxPackedIcon size={12} />
                        <Text color="success-textLow">Aprobado</Text>
                      </Tag>
                    )}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Card>
        {/*
        <Layout columns="1" gap="4">
          <Layout columns="3">
            <Layout.Section>
              <Card>
                <Card.Header title="Enviar productos de Siigo a Tiendanube" />
                <Card.Body>
                  <Box
                    display="flex"
                    flexDirection="column"
                    gap="4"
                    marginTop="4"
                  >
                    <Text as="span">
                      <strong>
                        {' '}
                        Selecciona el inventario desde donde quieres importar
                        productos
                      </strong>
                    </Text>
                    <Select
                      appearance="neutral"
                      id="productos-sync-inventory"
                      name="Name"
                      onChange={(event) => {
                        handleChangeInventorySyncProducts(event);
                      }}
                    >
                      <Select.Option
                        disabled
                        label="Seleccionar Inventario"
                        selected
                        value={
                          inventorySyncProducts
                            ? inventorySyncProducts.inventory_id
                            : 'Option default'
                        }
                      />
                      {inventories ? (
                        inventories.map((inventory: Inventory) => (
                          <Select.Option
                            key={inventory.inventory_id}
                            value={inventory.inventory_id}
                            label={inventory.name}
                          ></Select.Option>
                        ))
                      ) : (
                        <Select.Skeleton />
                      )}
                    </Select>
                    <Box display="flex" justifyContent="center" gap="4">
                      <Button
                        onClick={confirmSyncProducts}
                        appearance="primary"
                        disabled={isButtonDisabled || !flagSyncSiigoToTn}
                      >
                        Sincronizar
                      </Button>
                    </Box>
                    <Box
                      display={showMessageSync ? 'flex' : 'none'}
                      justifyContent="center"
                      gap="4"
                      margin="4"
                    >
                      <Text as="span" color="success-textLow">
                        <strong>
                          Sincronizando productos, luego de unos minutos podrás
                          ver los resultados en la sección de logs.
                        </strong>
                      </Text>
                    </Box>
                  </Box>
                </Card.Body>
              </Card>
              <Card>
                <Card.Header title="Enviar productos de Tiendanube a Siigo" />
                <Card.Body>
                  <Box
                    display="flex"
                    flexDirection="column"
                    gap="4"
                    marginBottom="4"
                  >
                    <Text as="span">
                      <strong>Impuesto a aplicar</strong>
                    </Text>
                    <Select
                      appearance="neutral"
                      id="sync-product-select-impuesto"
                      name="Name"
                      onChange={(event) => {
                        handleConfigTaxSyncProduct(event);
                      }}
                    >
                      <Select.Option
                        label="Seleccionar impuesto"
                        selected={true}
                        value="Seleccionar impuesto"
                      />
                      {taxes ? (
                        taxes.map((tax: Tax) => (
                          <Select.Option
                            key={tax.tax_id}
                            value={tax.tax_id}
                            label={tax.name}
                          ></Select.Option>
                        ))
                      ) : (
                        <Select.Skeleton />
                      )}
                    </Select>
                  </Box>
                  <Checkbox
                    name="control-inventario-checkbox"
                    label="Con control de inventario"
                  />
                  <Box
                    display="flex"
                    flexDirection="column"
                    gap="4"
                    marginTop="4"
                  >
                    <Text as="span">
                      <strong>
                        {' '}
                        Selecciona el inventario a donde quieres exportar
                        productos
                      </strong>
                    </Text>
                    <Select
                      appearance="neutral"
                      id="productos-sync-inventory-2"
                      name="Name"
                      onChange={(event) => {
                        handleChangeInventorySyncProducts2(event);
                      }}
                    >
                      <Select.Option
                        disabled
                        label="Seleccionar Inventario"
                        selected
                        value={
                          inventorySyncProducts
                            ? inventorySyncProducts.inventory_id
                            : 'Option default'
                        }
                      />
                      {inventories ? (
                        inventories.map((inventory: Inventory) => (
                          <Select.Option
                            key={inventory.inventory_id}
                            value={inventory.inventory_id}
                            label={inventory.name}
                          ></Select.Option>
                        ))
                      ) : (
                        <Select.Skeleton />
                      )}
                    </Select>
                    <Box display="flex" justifyContent="center" gap="4">
                      <Button
                        onClick={confirmSyncProducts2}
                        appearance="primary"
                        disabled={isButtonDisabled || !flagSyncTnToSiigo}
                      >
                        Sincronizar
                      </Button>
                    </Box>
                    <Box
                      display={showMessageSync2 ? 'flex' : 'none'}
                      justifyContent="center"
                      gap="4"
                      margin="4"
                    >
                      <Text as="span" color="success-textLow">
                        <strong>
                          Sincronizando productos, luego de unos minutos podrás
                          ver los resultados en la sección de logs.
                        </strong>
                      </Text>
                    </Box>
                  </Box>
                </Card.Body>
              </Card>
              <Card>
                <Card.Header title="Crear producto unitario en Tiendanube" />
                <Card.Body>
                  <Box
                    display="flex"
                    flexDirection="column"
                    gap="4"
                    marginTop="4"
                  >
                    <Text as="span">
                      <strong>
                        {' '}
                        Ingrese el código del producto de Siigo para crearlo en
                        Tiendanube
                      </strong>
                    </Text>
                    <FormField.Input
                      appendPosition="start"
                      placeholder="Ejemplo: XXX123"
                      onChange={(event) => {
                        handleChangeSiigoCode(event);
                      }}
                    />
                    <Button onClick={createProduct} appearance="primary">
                      Crear producto
                    </Button>
                  </Box>
                </Card.Body>
              </Card>
            </Layout.Section>
            <Layout.Section>
              <Card>
                <Card.Header title="Configuración de facturación" />
                <Card.Body>
                  <Box display="flex" flexDirection="column" gap="4">
                    <Box display="flex" flexDirection="row" gap="2">
                      <Text as="span"> Estado: </Text>
                      <Tag appearance={orderIsActive ? 'primary' : 'danger'}>
                        <Text>{orderIsActive ? 'Activo' : 'Inactivo'}</Text>
                      </Tag>
                    </Box>
                    <Text as="span">
                      <strong>Procesar factura</strong>
                    </Text>
                    <Select
                      appearance="neutral"
                      id="ordenes-select-envio"
                      name="Name"
                      onChange={(event) => {
                        handleGenerateOrderOn(event);
                      }}
                    >
                      <Select.Option
                        disabled
                        label="Seleccionar"
                        value="Seleccionar"
                        selected={generateOrderOn == null}
                      />
                      <Select.Option
                        label="AL CREAR"
                        value="AL CREAR"
                        selected={generateOrderOn == 'AL CREAR' ? true : false}
                      />
                      <Select.Option
                        label="AL PAGAR"
                        value="AL PAGAR"
                        selected={generateOrderOn == 'AL PAGAR' ? true : false}
                      />
                    </Select>
                    <Text as="span">
                      <strong>Asignar documento Factura</strong>
                    </Text>
                    <Select
                      appearance="neutral"
                      id="ordenes-select-documento"
                      name="Name"
                      onChange={(event) => {
                        handleConfigDocument(event);
                      }}
                    >
                      <Select.Option
                        disabled
                        label="Seleccionar documento"
                        selected={
                          configDocument?.document_siigo_id ? false : true
                        }
                        value="Seleccionar documento"
                      />
                      {documents ? (
                        documents.map((document: Document) => (
                          <Select.Option
                            key={document.document_siigo_id}
                            value={document.document_siigo_id}
                            label={
                              document.name +
                              ' (código: ' +
                              document.document_siigo_code +
                              ')'
                            }
                            selected={
                              configDocument?.document_siigo_id ==
                              document.document_siigo_id
                                ? true
                                : false
                            }
                          ></Select.Option>
                        ))
                      ) : (
                        <Select.Skeleton />
                      )}
                    </Select>
                    <Text as="span">
                      <strong>Asignar impuesto</strong>
                    </Text>
                    <Select
                      appearance="neutral"
                      id="ordenes-select-impuesto"
                      name="Name"
                      onChange={(event) => {
                        handleConfigTax(event);
                      }}
                    >
                      <Select.Option
                        disabled
                        label="Seleccionar impuesto"
                        selected={configTax?.tax_id ? false : true}
                        value="Seleccionar impuesto"
                      />
                      {taxes ? (
                        taxes.map((tax: Tax) => (
                          <Select.Option
                            key={tax.tax_id}
                            value={tax.tax_id}
                            label={tax.name}
                            selected={
                              configTax?.tax_id == tax.tax_id ? true : false
                            }
                          ></Select.Option>
                        ))
                      ) : (
                        <Select.Skeleton />
                      )}
                    </Select>
                    <Text as="span">
                      <strong>Centro de costo</strong>
                    </Text>
                    <Select
                      appearance="neutral"
                      id="ordenes-select-cost-center"
                      name="Name"
                      onChange={(event) => {
                        handleConfigCostCenter(event);
                      }}
                    >
                      <Select.Option
                        disabled
                        label="Seleccionar centro"
                        selected={
                          configCostCenter?.cost_center_id ? false : true
                        }
                        value="Seleccionar centro"
                      />
                      {costCenters ? (
                        costCenters.map((costCenter: CostCenter) => (
                          <Select.Option
                            key={costCenter.cost_center_id}
                            value={costCenter.cost_center_id}
                            label={costCenter.name}
                            selected={
                              configCostCenter?.cost_center_id ==
                              costCenter.cost_center_id
                                ? true
                                : false
                            }
                          ></Select.Option>
                        ))
                      ) : (
                        <Select.Skeleton />
                      )}
                    </Select>
                    <Text as="span">
                      <strong>Enviar factura</strong>
                    </Text>
                    <Checkbox
                      name="dian-checkbox"
                      label="Enviar a la DIAN"
                      checked={dian}
                      disabled={!flagDian}
                      onChange={(event) => {
                        handleChangeDianCheckbox(event);
                      }}
                    />
                    <Checkbox
                      name="email-checkbox"
                      label="Enviar email cliente al crear factura"
                      checked={email}
                      disabled={!flagEmail}
                      onChange={(event) => {
                        handleChangeEmailCheckbox(event);
                      }}
                    />
                    <Box
                      display="flex"
                      flexDirection="column"
                      gap="4"
                      marginTop="4"
                    >
                      <Box
                        borderStyle="none"
                        display="flex"
                        flexDirection="row"
                        gap="2"
                      >
                        <Text as="span">
                          <strong>Medio de envío</strong>
                        </Text>
                        <Box maxWidth="200px">
                          <Tooltip
                            content="Configura un producto para envios para asociar a la factura, el cual informará, como un item de producto más, el monto del envío que se le cobró al cliente.
                          Para más información accede al instructivo y visita la sección 'Automatización de facturas electrónicas' "
                          >
                            {<QuestionCircleIcon />}
                          </Tooltip>
                        </Box>
                      </Box>
                      <FormField.Input
                        appendPosition="start"
                        label="Asociar item medio de envío"
                        placeholder="Ejemplo: XXX123"
                        onChange={(event) => {
                          handleChangeSiigoShippingItem(event);
                        }}
                      />
                      <Box display="flex" justifyContent="flex-start" gap="4">
                        <Button
                          onClick={vinculateShippingItem}
                          appearance="neutral"
                        >
                          Asociar
                        </Button>
                        <Box display="flex" flexDirection="column" gap="2">
                          <Text as="span">
                            Estado:{' '}
                            <strong>
                              {siigoShippingItemCode
                                ? 'VINCULADO'
                                : 'NO VINCULADO'}
                            </strong>
                          </Text>
                          <Text
                            as="span"
                            hidden={
                              siigoShippingItemCode == null ||
                              siigoShippingItemCode == ''
                            }
                          >
                            Codigo: {siigoShippingItemCode}
                          </Text>
                          <Text
                            as="span"
                            hidden={
                              siigoShippingItemCode == null ||
                              siigoShippingItemCode == ''
                            }
                          >
                            {' '}
                            Nombre: {siigoShippingItemName}
                          </Text>
                        </Box>
                      </Box>
                    </Box>
                    <Box borderStyle="none" marginTop="4">
                      <Box
                        borderStyle="none"
                        display="flex"
                        flexDirection="row"
                        gap="2"
                      >
                        <Text as="span">
                          <strong>Vinculación de pagos</strong>
                        </Text>
                        <Tooltip
                          content="Vincule cada medio de pago de Tiendanube con el pago que se reflejará en Siigo.
                          El medio de pago por default se utilizará en caso de no poder concretarse la creación de la factura electrónica
                          con el medio de pago vinculado. Para más información accede al instructivo y visita la sección 'Automatización de facturas electrónicas' "
                        >
                          {<QuestionCircleIcon />}
                        </Tooltip>
                      </Box>
                      <Box borderStyle="none" marginTop="4">
                        <Layout columns="2 - symmetric">
                          <Layout.Section>
                            <Text
                              as="span"
                              textAlign="center"
                              fontSize="base"
                              fontWeight="medium"
                            >
                              Tiendanube
                            </Text>
                            <Box marginTop="2">
                              {paymentsTn ? (
                                paymentsTn.map((payment: PaymentTn) => (
                                  <Box
                                    key={payment.payment_tn_id}
                                    marginBottom="6"
                                  >
                                    <div
                                      key={payment.payment_tn_id}
                                      data-tn-id={payment.payment_tn_id}
                                    >
                                      <Text>{payment.name}</Text>
                                    </div>
                                  </Box>
                                ))
                              ) : (
                                <div></div>
                              )}
                            </Box>
                          </Layout.Section>
                          <Layout.Section>
                            <Text
                              as="span"
                              textAlign="center"
                              fontSize="base"
                              fontWeight="medium"
                            >
                              Siigo
                            </Text>
                            {paymentsTn ? (
                              paymentsTn.map((tn_payment: PaymentTn) => (
                                <Select
                                  appearance="neutral"
                                  id={tn_payment.payment_tn_id}
                                  key={tn_payment.payment_tn_id}
                                  name={tn_payment.name}
                                  onChange={(event) => {
                                    handleConfigPayments(event);
                                  }}
                                >
                                  <Select.Option
                                    label="Seleccionar medio de pago"
                                    value="Seleccionar medio de pago"
                                    selected={configPayments.some((config) => {
                                      if (
                                        tn_payment.payment_tn_id ==
                                          config.payment_tn_id &&
                                        config.payment_siigo_id == null
                                      ) {
                                        console.log(
                                          tn_payment.payment_tn_id +
                                            ' is equal to ' +
                                            config.payment_tn_id +
                                            'and ' +
                                            config.payment_siigo_id +
                                            ' is null',
                                        );
                                        return true;
                                      }
                                      return false;
                                    })}
                                  />
                                  {paymentsSiigo ? (
                                    paymentsSiigo.map(
                                      (siigo_payment: PaymentSiigo) => (
                                        <Select.Option
                                          key={siigo_payment.payment_siigo_id}
                                          value={siigo_payment.payment_siigo_id}
                                          label={siigo_payment.name}
                                          selected={configPayments.some(
                                            (config) => {
                                              if (
                                                config.payment_tn_id ==
                                                  tn_payment.payment_tn_id &&
                                                config.payment_siigo_id ==
                                                  siigo_payment.payment_siigo_id
                                              ) {
                                                return true;
                                              }
                                              return false;
                                            },
                                          )}
                                        ></Select.Option>
                                      ),
                                    )
                                  ) : (
                                    <div></div>
                                  )}
                                </Select>
                              ))
                            ) : (
                              <div></div>
                            )}
                          </Layout.Section>
                        </Layout>
                      </Box>
                    </Box>
                    <Box display="flex" marginTop="4">
                      <Button onClick={saveConfigOrders} appearance="primary">
                        Guardar configuración ordenes
                      </Button>
                    </Box>
                  </Box>
                </Card.Body>
              </Card>
              <Modal onDismiss={onModalSyncClose} open={isModalOpen}>
                <React.Fragment key=".0">
                  <Modal.Header title="ATENCIÓN" />
                  <Modal.Body padding="none">
                    <Text as="span">
                      Inventario seleccionado:{' '}
                      <strong> {inventory?.name}</strong>
                      <p>
                        Si acepta estará enviando todos los productos del
                        Inventario de Siigo a Tiendanube.
                      </p>
                      <p> Requisitos a tener en cuenta:</p>
                      <Box marginLeft="12" gap="4" marginBottom="2">
                        <ul>
                          <li>Todos los productos deben tener SKU cargado</li>
                          <li>
                            No se podrá revertir la acción una vez efectuada
                          </li>
                        </ul>
                      </Box>
                      <p>Además , tener en cuenta que: </p>
                      <p>
                        Los productos se crearán sin fotos y como{' '}
                        <strong>productos independientes</strong> (sin
                        variantes)
                      </p>
                      <Box marginTop="2" marginBottom="2">
                        <Text as="p">
                          La sincronización correrá en segundo plano y el tiempo
                          de sincronización dependerá del tamaño de su catalogo.
                        </Text>
                      </Box>
                      <p>
                        Ante cualquier duda, podrá revisar el instructivo
                        haciendo click aquí, donde se explica con más detalle
                        este apartado.
                      </p>
                    </Text>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button onClick={onModalSyncClose} appearance="danger">
                      CANCELAR
                    </Button>
                    <Button onClick={syncProducts} appearance="primary">
                      ACEPTAR
                    </Button>
                  </Modal.Footer>
                </React.Fragment>
              </Modal>
              <Modal onDismiss={onModalSyncClose2} open={isModalOpen2}>
                <React.Fragment key=".0">
                  <Modal.Header title="ATENCIÓN" />
                  <Modal.Body padding="none">
                    <Text as="span">
                      <p>
                        Inventario seleccionado:{' '}
                        <strong> {inventory2?.name}</strong>
                      </p>
                      <p>
                        Impuesto seleccionado: <strong> {taxSync?.name}</strong>
                      </p>
                      <p>
                        Si acepta estará enviando todos los productos de
                        Tiendanube al inventario seleccionado Siigo.{' '}
                      </p>
                      <p> Requisitos a tener en cuenta:</p>
                      <Box marginLeft="12" gap="4">
                        <ul>
                          <li>Todos los productos deben tener SKU cargado</li>
                          <li>
                            Si el producto cuenta con variantes, se crearan como{' '}
                            <strong>productos individuales</strong>
                          </li>
                          <li>
                            No se cargaran con imagenes ya que Siigo no lo
                            admite
                          </li>
                          <li>
                            No se podrá revertir la acción una vez efectuada
                          </li>
                        </ul>
                      </Box>
                      <Box marginTop="2" marginBottom="2">
                        <Text as="p">
                          La sincronización correrá en segundo plano y el tiempo
                          de sincronización dependerá del tamaño de su catalogo.
                        </Text>
                      </Box>
                      <p>
                        Ante cualquier duda, podrá revisar el instructivo
                        haciendo click aquí, donde se explica con más detalle
                        este apartado.
                      </p>
                    </Text>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button onClick={onModalSyncClose2} appearance="danger">
                      CANCELAR
                    </Button>
                    <Button onClick={syncProducts2} appearance="primary">
                      ACEPTAR
                    </Button>
                  </Modal.Footer>
                </React.Fragment>
              </Modal>
            </Layout.Section>
            <Layout.Section>
              <Card>
                <Card.Header title="Enviar clientes de Tiendanube a Siigo" />
                <Card.Body>
                  <Box display="flex" justifyContent="center" gap="4">
                    <Button
                      onClick={syncClients}
                      disabled={isButtonDisabled}
                      appearance="primary"
                    >
                      Sincronizar
                    </Button>
                  </Box>
                </Card.Body>
              </Card>
              <Card>
                <Card.Header title="Sincronización automática de stock" />
                <Card.Body>
                  <Box
                    display="flex"
                    flexDirection="column"
                    gap="4"
                    marginTop="4"
                  >
                    <Text as="span">
                      <strong>
                        {' '}
                        Selecciona el inventario desde donde se sincronizarán
                        stocks
                      </strong>
                    </Text>
                    <Select
                      appearance="neutral"
                      id="stock-sync-inventory"
                      name="Name"
                      onChange={(event) => {
                        handleChangeInventorySyncStock(event);
                      }}
                    >
                      <Select.Option
                        label="Seleccionar Inventario"
                        selected={
                          inventorySyncStock?.inventory_id ? false : true
                        }
                        value="Seleccionar inventario"
                      />
                      {inventories ? (
                        inventories.map((inventory: Inventory) => (
                          <Select.Option
                            key={inventory.inventory_id}
                            value={inventory.inventory_id}
                            label={inventory.name}
                            selected={
                              inventorySyncStock?.inventory_id ==
                              inventory.inventory_id
                                ? true
                                : false
                            }
                          ></Select.Option>
                        ))
                      ) : (
                        <Select.Skeleton />
                      )}
                    </Select>
                    <Text as="span">
                      <strong>
                        {' '}
                        Selecciona la bodega desde donde se sincronizarán stocks
                      </strong>
                    </Text>
                    <Select
                      appearance="neutral"
                      id="stock-sync-bodega"
                      name="Name"
                      onChange={(event) => {
                        handleChangeWarehouseSyncStock(event);
                      }}
                    >
                      <Select.Option
                        label="Seleccionar Bodega"
                        selected={
                          warehouseSyncStock?.warehouse_id ? false : true
                        }
                        value="Seleccionar bodega"
                      />
                      {warehouses ? (
                        warehouses.map((warehouse: Warehouse) => (
                          <Select.Option
                            key={warehouse.warehouse_id}
                            value={warehouse.warehouse_id}
                            label={warehouse.name}
                            selected={
                              warehouseSyncStock?.warehouse_id ==
                              warehouse.warehouse_id
                                ? true
                                : false
                            }
                          ></Select.Option>
                        ))
                      ) : (
                        <Select.Skeleton />
                      )}
                    </Select>
                    <Box display="flex" justifyContent="center" gap="4">
                      <Button
                        onClick={saveSyncStocksConfig}
                        appearance="primary"
                      >
                        Guardar
                      </Button>
                    </Box>
                  </Box>
                </Card.Body>
              </Card>

              <Card>
                <Card.Header title="Registros" />
                <Card.Body>
                  <Box display="flex" flexDirection="column" gap="4">
                    <Text as="span"> Registros de acciones efectuadas</Text>
                    <Box display="flex" justifyContent="center" gap="4">
                      <Button onClick={goToLogs} appearance="neutral">
                        Ver registros
                      </Button>
                    </Box>
                  </Box>
                </Card.Body>
              </Card>
            </Layout.Section>
          </Layout>
        </Layout>
        */}
      </Page.Body>
    </Page>
  );
};

export default ConfigPage;
