import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  Icon,
  Button,
  Box,
  IconButton,
  Card,
  Text,
  Link,
  Title,
} from '@nimbus-ds/components';
import { Layout, Page } from '@nimbus-ds/patterns';
import { CogIcon, ExternalLinkIcon, RocketIcon } from '@nimbus-ds/icons';
import tokens from '@nimbus-ds/tokens/dist/js/tokens';
import { navigateHeaderRemove } from '@tiendanube/nexo/helpers';

import nexo from '../../nexoClient';
import { useWindowWidth } from '../../hooks';

import axiosInstance from '../../axios';

function PageDashboard() {
  const { push } = useHistory();
  const windowWidth = useWindowWidth();

  const breakpointMd = tokens.breakpoint.md.value.replace('px', '');

  const [installed, setInstalled] = useState(0);
  const [uninstalled, setUninstalled] = useState(0);
  const [approved, setApproved] = useState(0);
  const [pending, setPending] = useState(0);
  const [rejected, setRejected] = useState(0);

  const handleGoToSellersView = async () => {
    push('/setup');
  };

  const handleGoToConfig = async () => {
    push('/config');
  };

  const handleGoToLogs = async () => {
    push('/logs');
  };

  const handleGoToMetrics = async () => {
    push('/metrics');
  };

  const actions =
    windowWidth !== null && windowWidth <= breakpointMd ? (
      <IconButton
        backgroundColor="transparent"
        borderColor="transparent"
        onClick={handleGoToConfig}
        source={<CogIcon />}
        size="2rem"
      />
    ) : (
      <Box display="flex" gap="4">
        <Button onClick={handleGoToSellersView}>
          <Icon source={<CogIcon />} color="currentColor" />
          Sellers
        </Button>
        <Button appearance="primary" onClick={handleGoToConfig}>
          <Icon source={<CogIcon />} color="currentColor" />
          Productos
        </Button>
        <Button appearance="primary" onClick={handleGoToLogs}>
          <Icon source={<CogIcon />} color="currentColor" />
          Registros
        </Button>
      </Box>
    );

  useEffect(() => {
    navigateHeaderRemove(nexo);
  }, []);

  const getDashboardMetrics = async () => {
    console.log('getting dashboard metrics...');
    try {
      await axiosInstance
        .get('/dashboard_metrics')
        .then((response) => {
          if (response.data.status == true) {
            setInstalled(response.data.installed_sellers);
            setUninstalled(response.data.uninstalled_sellers);
            setApproved(response.data.approved_sellers);
            setPending(response.data.pending_sellers);
            setRejected(response.data.rejected_sellers);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (e) {
      console.error(e);
    }
    return;
  };
  getDashboardMetrics();

  return (
    <Page maxWidth="1000px">
      <Page.Header title="" buttonStack={actions} />
      <Page.Body>
        <Layout columns="1">
          <Layout.Section>
            <Card>
              <Card.Header title="Bienvenido/a" />
              <Card.Body>
                <Text as="p" fontSize="highlight">
                  <p></p>
                </Text>
              </Card.Body>
            </Card>
            <Card>
              <Card.Body>
                <Text as="span" fontSize="highlight">
                  <Box marginTop="2" marginBottom="2">
                    <p></p>
                  </Box>
                </Text>
                <Box
                  display="flex"
                  justifyContent="center"
                  gap="4"
                  marginTop="6"
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    padding="10"
                    backgroundColor="success-surfaceHighlight"
                  >
                    <Title fontSize="4">INSTALADAS</Title>
                    <Title fontSize="6">
                      <strong>{installed}</strong>
                    </Title>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    padding="10"
                    backgroundColor="success-interactive"
                  >
                    <Title fontSize="4">APROBADAS</Title>
                    <Title fontSize="6">
                      <strong>{approved}</strong>
                    </Title>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    padding="10"
                    backgroundColor="warning-interactive"
                  >
                    <Title fontSize="4">PENDIENTES</Title>
                    <Title fontSize="6">
                      <strong>{pending}</strong>
                    </Title>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    padding="10"
                    backgroundColor="warning-surfaceHighlight"
                  >
                    <Title fontSize="4">RECHAZADAS</Title>
                    <Title fontSize="6">
                      <strong>{rejected}</strong>
                    </Title>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    padding="10"
                    backgroundColor="danger-interactive"
                  >
                    <Title fontSize="4">DESINSTALADAS</Title>
                    <Title fontSize="6">
                      <strong>{uninstalled}</strong>
                    </Title>
                  </Box>
                </Box>
                <Box display="flex" justifyContent="center" marginTop="10">
                  <Button onClick={handleGoToMetrics} appearance="primary">
                    Ver Metricas
                  </Button>
                </Box>
              </Card.Body>
            </Card>
          </Layout.Section>
        </Layout>
      </Page.Body>
    </Page>
  );
}

export default PageDashboard;
